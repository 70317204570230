import {GET_LL_CONFIG, SET_LL_CONFIG, ERROR_LL_CONFIG, SET_LL_HASH} from '../constants/llConfigConstants';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';

const persistConfig = {
    key: 'llConfig',
    storage: storageSession,
    whitelist: ['llHash']
}

const initialState = {
    'loading': false,
    'error': false,
    'cartData': false,
    'llHash': null
};

function llConfigReducer(state = initialState, action) {
    switch(action.type){
        case GET_LL_CONFIG:
            return {...state, 'loading':true};
        case SET_LL_CONFIG:
            return {...state, 'cartData': action.payload, 'loading':false};
        case ERROR_LL_CONFIG:
            return {...state, 'cartData': action.payload, 'loading':false};
        case SET_LL_HASH:
            return {...state, 'llHash': action.payload};
        default:
            return state;
    }
}

export default persistReducer(persistConfig, llConfigReducer);