import {GET_COUNTRIES, SET_COUNTRIES, ERROR_COUNTRIES} from '../constants/countriesConstants';

const initialState = {
    'loading': false,
    'error': false,
    'countries': []
};

function countriesReducer(state = initialState, action) {
    switch(action.type){
        case GET_COUNTRIES:
            return {...state, 'loading':true};
        case SET_COUNTRIES:
            return {...state, 'loading':false, 'countries': action.payload};
        case ERROR_COUNTRIES:
            return {...state, 'loading':false, 'countries': []};
        default:
            return state;
    }
}

export default countriesReducer;