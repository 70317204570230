import {GET_LL_CONFIG, SET_LL_CONFIG, ERROR_LL_CONFIG, SET_LL_HASH} from '../constants/llConfigConstants';
import Checkout from '../../classes/checkout';
import { SET_COUNTRIES } from '../constants/countriesConstants';
import Places from '../../classes/places';
import ls from "local-storage";

export const getLlConfig = (llHash=null, cartInfo=false) => {
    return async function(dispatch, getState) {
        if(cartInfo !== false)
        {
            dispatch(setLlConfig(cartInfo));
            return false;
        }
        let response = await Checkout.getCartData(llHash);
        if (response.cartInfo) {
            response.cartInfo = {
                ...response.cartInfo,
                checkout_custom_theme: Checkout.updateCssObject(response.cartInfo.checkout_custom_theme, response.cartInfo.checkout_font)
            };
            let countryList = await Places.getCountries(await getState().countries.countries, response.cartInfo.countries);
            dispatch({'type':SET_COUNTRIES, 'payload':countryList});
            dispatch(setLlConfig(response.cartInfo));
        }else{
            dispatch({'type':ERROR_LL_CONFIG, 'payload':false});
        }
    };
}

export const getCrmConfig = (llHash=null) => {
    return async function(dispatch, getState) {
        dispatch({'type':GET_LL_CONFIG});
        let response = await Checkout.getCrmConfigData(llHash);
        if (response) {
            if(!getState().llConfig.cartData)
            {
                response.cartInfo = {
                    ...response.cartInfo,
                    checkout_custom_theme: Checkout.updateCssObject(response.cartInfo.checkout_custom_theme, response.cartInfo.checkout_font)
                };
                dispatch(setLlConfig(response.cartInfo));
                if(response.cartInfo.hasToken)
                {
                    dispatch({'type':GET_LL_CONFIG});
                }
            }
        }else{
            dispatch({'type':ERROR_LL_CONFIG, 'payload':false});
        }
    };
}

export const setLlConfig = (configData) => {
    ls.set('cartInfo', { 'cartInfo': configData });
    return {'type':SET_LL_CONFIG, 'payload':configData};
}

export const setLlHash = (llHash) => {
    return {
        'type': SET_LL_HASH,
        'payload': llHash
    };
}