import {GET_COUNTRIES, SET_COUNTRIES, ERROR_COUNTRIES} from '../constants/countriesConstants';
import axios from 'axios';

export const getCountries = () => {
    return async function(dispatch) {
        dispatch({'type':GET_COUNTRIES});
        await axios
        .get(window.location.origin + "/json/countries.json")
        .then(countries => {
            dispatch({'type':SET_COUNTRIES, 'payload':countries.data});
        })
        .catch(err => dispatch({'type':ERROR_COUNTRIES}));
    };
}